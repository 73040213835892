import { styled } from "~/styles/stitches.config";
import { ReactNode } from "react";

const Container = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  "@bp1": {
    alignItems: "center",
  },
});

const Content = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  "@bp1": {
    maxWidth: 1080,
  },
});

export interface PaddedScreenContainerProps {
  children?: ReactNode;
}

export const PaddedScreenContainer = ({
  children,
}: PaddedScreenContainerProps) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};
