import React, { forwardRef, ReactNode } from "react";
import { keyframes, styled } from "~/styles/stitches.config";
import * as Accordion from "@radix-ui/react-accordion";
import { AccordionTriggerProps as RadixAccordionTriggerProps } from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@sofia-eng/web-icons";

export interface AccordionItemProps {
  title: string;
  text: ReactNode;
  value: string;
}

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const AccordionItemContainer = styled(Accordion.Item, {
  overflow: "hidden",
  marginTop: 3,
  backgroundColor: "$linen",
  "&:first-child": {
    marginTop: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },

  "&:last-child": {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});

const AccordionHeader = styled(Accordion.Header, {
  all: "unset",
  display: "flex",
  width: "100%",
  cursor: "pointer",
});

const AccordionHeaderTrigger = styled(Accordion.Trigger, {
  cursor: "pointer",
  all: "unset",
  width: "100%",
  fontFamily: "inherit",
  backgroundColor: "transparent",
  p: ["$3"],
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 8,
  textAlign: "left",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "22px",
  color: "$blueGray300",
});

const AccordionIconContainer = styled("div", {
  width: 24,
  height: 24,
  display: "block",
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

const AccordionContent = styled(Accordion.Content, {
  overflow: "hidden",

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled("div", {
  pb: "$3",
  pl: "$3",
  pr: "$6",
  textAlign: "left",
  fontSize: 14,
  fontWeight: "300",
  lineHeight: "20px",
  "& p": {
    textAlign: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
    pb: "$1",
  },
});

interface AccordionTriggerProps extends RadixAccordionTriggerProps {
  children?: ReactNode;
}

const AccordionTrigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, ...props }, forwardRef) => (
    <AccordionHeader>
      <AccordionHeaderTrigger ref={forwardRef} {...props}>
        {children}
        <AccordionIconContainer>
          <ChevronDownIcon size={"md"} aria-hidden />
        </AccordionIconContainer>
      </AccordionHeaderTrigger>
    </AccordionHeader>
  ),
);

export const AccordionItem = ({ value, text, title }: AccordionItemProps) => {
  return (
    <AccordionItemContainer value={value}>
      <AccordionTrigger>{title}</AccordionTrigger>
      <AccordionContent>
        <StyledContentText>{text}</StyledContentText>
      </AccordionContent>
    </AccordionItemContainer>
  );
};
