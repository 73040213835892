import React, { ReactNode } from "react";
import { CSS, styled } from "~/styles/stitches.config";

const GridContainer = styled("div", {
  display: "grid",
  paddingLeft: 14,
  paddingRight: 14,
  gridColumnGap: "20px",
  gridTemplateColumns: "repeat(4, 1fr)",
  "@bp1": {
    paddingLeft: 0,
    paddingRight: 0,
    gridColumnGap: "20px",
    gridTemplateColumns: "repeat(12, 1fr)",
  },
});

export interface GridProps {
  children?: ReactNode;
  css?: CSS;
}

export const Grid = ({ children, css }: GridProps) => {
  return <GridContainer css={css}>{children}</GridContainer>;
};
