import React, { useMemo } from "react";
import { DoctorFragment } from "~/types/graphql";
import { styled } from "~/styles/stitches.config";
import { IconStatus } from "~/components";
import { ConsultIcon, ChevronRightIcon } from "@sofia-eng/web-icons";
import { hasTemporaryVirtualOffice } from "~/utils/office_address_utils";
import AddressItem from "./AddressItem";

export interface DoctorListItemProps {
  doctor: DoctorFragment;
  onClick?: VoidFunction;
  onHover?: VoidFunction;
  onHoverOut?: VoidFunction;
}

const Container = styled("div", {
  display: "flex",
  p: ["$2"],
  flexDirection: "column",
  borderRadius: 8,
  border: "2px solid $blueGray50",
  backgroundColor: "$white",
  overflow: "hidden",
  cursor: "pointer",
  flexShrink: 0,
  transition: "all 0.25s ease-out",
  "&:hover": {
    boxShadow: "0px 3px 8px 0px #E9E9E9",
  },
});

const DoctorBasicInfo = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 8,
  alignItems: "center",
});

const DoctorBasicInfoText = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 4,
  flex: 1,
});

const DoctorName = styled("p", {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "20px",
});

const DoctorSpecialization = styled("p", {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
});

const Divider = styled("div", {
  backgroundColor: "$blueGray50",
  height: 2,
  width: "100%",
  mt: "$2",
  mb: "$2",
});

const InfoContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: 8,
});

const InfoRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 8,
  width: "100%",
});

const InfoRowColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "calc(100% - 24px)",
  gap: 4,
});

const InfoSubtitle = styled("p", {
  color: "$blueGray200",
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "20px",
  maxLines: 1,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
});

const DoctorListItem = ({
  doctor,
  onClick,
  onHover,
  onHoverOut,
}: DoctorListItemProps) => {
  const doctorSpecializations = useMemo(() => {
    if (
      doctor.doctorSpecialistDetails &&
      doctor.doctorSpecialistDetails.length > 0
    ) {
      return doctor.doctorSpecialistDetails[0].specializationName;
    }
    return "-";
  }, [doctor]);

  const doctorOnlineStatus = useMemo(() => {
    if (hasTemporaryVirtualOffice(doctor)) {
      return "En línea - presencial";
    }
    return "Presencial";
  }, [doctor]);

  const onMouseEnterHandler = () => {
    onHover?.();
  };

  const onMouseLeaveHandler = () => {
    onHoverOut?.();
  };

  return (
    <Container
      onClick={onClick}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <DoctorBasicInfo>
        <IconStatus icon={"DoctorIcon"} />
        <DoctorBasicInfoText>
          <DoctorName>{doctor.fullName}</DoctorName>
          <DoctorSpecialization>{doctorSpecializations}</DoctorSpecialization>
        </DoctorBasicInfoText>
        <ChevronRightIcon size={"md"} color={"primary500"} />
      </DoctorBasicInfo>
      <Divider />
      <InfoContainer>
        {doctor.officeAddresses?.map(officeAddress => (
          <AddressItem key={officeAddress.id} officeAddress={officeAddress} />
        ))}
        <InfoRow>
          <ConsultIcon color={"blueGray200"} />
          <InfoRowColumn>
            <InfoSubtitle>{doctorOnlineStatus}</InfoSubtitle>
          </InfoRowColumn>
        </InfoRow>
      </InfoContainer>
    </Container>
  );
};

export default DoctorListItem;
