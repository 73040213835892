import React from "react";
import Image from "next/image";
import { Button, Grid, PaddedScreenContainer } from "~/components";
import { CSS, styled } from "~/styles/stitches.config";

const gridCss: CSS = {
  mb: "$10",
  alignContent: "center",
  alignItems: "center",
  "@bp1": {
    marginBottom: 100,
  },
};

const ImagesContainer = styled("div", {
  gridColumn: "1/-1",
  display: "flex",
  flexDirection: "row",
  gap: 22,
  alignItems: "center",
  mb: "$4",
  position: "relative",
  "@bp1": {
    gap: 32,
    marginBottom: 0,
    gridColumn: "2/span 6",
    paddingRight: 40,
  },
});

const AspectRatio = styled("div", {
  position: "relative",
  flex: 1,
});

const TextContainer = styled("div", {
  gridColumn: "1/-1",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: 8,
  mb: "$3",
  "@bp1": {
    gap: 16,
    gridColumn: "8/span 4",
    textAlign: "left",
    justifyContent: "flex-start",
    marginBottom: 0,
  },
});

const ButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 8,
  justifyContent: "center",
  alignItems: "center",
  mt: "$2",
  "@bp1": {
    gap: 24,
    justifyContent: "flex-start",
  },
});

export const HomeQuote = () => {
  return (
    <PaddedScreenContainer>
      <Grid css={gridCss}>
        <ImagesContainer>
          <AspectRatio css={{ aspectRatio: "169 / 339" }}>
            <Image
              src={"/cotiza-1@2x.png"}
              alt={"test"}
              quality={100}
              fill
              loading={"lazy"}
            />
          </AspectRatio>
          <AspectRatio css={{ aspectRatio: 1, "@bp1": { width: 216 } }}>
            <Image
              src={"/cotiza-2@2x.png"}
              alt={"test"}
              quality={100}
              fill
              loading={"lazy"}
            />
          </AspectRatio>
        </ImagesContainer>
        <TextContainer>
          <h4>¡Cotiza tu seguro!</h4>
          <p>
            Un seguro para cubrir cuidados médicos chicos o hasta
            hospitalizaciones.
          </p>
          <ButtonsContainer>
            <Button
              title={"Cotiza ahora"}
              onClick={() =>
                window.open(
                  "https://cotiza.sofiasalud.com/?_ga=2.19392381.410664251.1688508996-1549897363.1686765548",
                )
              }
            />
            <Button
              title={"Conoce más"}
              type={"ghost"}
              onClick={() =>
                window.open("https://www.sofiasalud.com/individual")
              }
            />
          </ButtonsContainer>
        </TextContainer>
      </Grid>
    </PaddedScreenContainer>
  );
};
