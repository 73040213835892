import React from "react";
import { DoctorFragment, OfficeAddressFragment } from "~/types/graphql";
import { styled } from "~/styles/stitches.config";
import Flex from "~/components/flex/Flex";
import {
  ArrowMapDirectionIcon,
  InPersonConsultIcon,
  VideoConsultIcon,
} from "@sofia-eng/web-icons";
import {
  getAddressUrl,
  getFullPhysicalAddress,
} from "~/utils/office_address_utils";

export interface DoctorProfileAddressesProps {
  doctor: DoctorFragment;
}

const Title = styled("h4", {
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "22px",
  color: "$blueGray300",
});

export const DoctorProfileAddresses = ({
  doctor,
}: DoctorProfileAddressesProps) => {
  return (
    <Flex direction={"column"} gap={"3"}>
      <>
        <Title>Ubicaciones</Title>
        {doctor.officeAddresses?.map((address, index) => {
          if (address.temporaryVirtualOffice) {
            return <VirtualOffice key={index} />;
          }
          return <DoctorProfileAddress key={index} address={address} />;
        })}
      </>
    </Flex>
  );
};

interface DoctorProfileAddressProps {
  address: OfficeAddressFragment;
}

const OfficeAddressIndex = styled("div", {
  fontSize: 14,
  fontWeight: 700,
  color: "$blueGray300",
  lineHeight: "18px",
});

const AddressTitle = styled("div", {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "24px",
  color: "$blueGray200",
});

const AddressDetails = styled("div", {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  color: "$blueGray200",
});

const Link = styled("a", {
  fontWeight: 700,
  display: "flex",
  flexDirection: "row",
  gap: 8,
  alignItems: "center",
});

const DoctorProfileAddress = ({ address }: DoctorProfileAddressProps) => {
  return (
    <Flex direction={"column"} gap={"1"}>
      <Flex direction={"row"} gap={"1"} align={"center"}>
        <InPersonConsultIcon size={"sm"} color={"blueGray300"} />
        <OfficeAddressIndex>{`Consultorio Presencial`}</OfficeAddressIndex>
      </Flex>
      <AddressTitle>{address.address2}</AddressTitle>
      <AddressDetails>{getFullPhysicalAddress(address)}</AddressDetails>
      <Link
        href={getAddressUrl(address)}
        target={"_blank"}
        referrerPolicy={"no-referrer"}
        rel="noreferrer"
      >
        <ArrowMapDirectionIcon size={"sm"} color={"purple500"} />
        Ver en Google Maps
      </Link>
    </Flex>
  );
};

const VirtualOffice = () => {
  return (
    <Flex direction={"column"} gap={"1"}>
      <Flex direction={"row"} gap={"1"} align={"center"}>
        <VideoConsultIcon size={"sm"} color={"blueGray300"} />
        <OfficeAddressIndex>Consulta en línea</OfficeAddressIndex>
      </Flex>
      <AddressDetails>
        Recibirás un link para tener una videoconsulta con el especialista
        cuando agendes la consulta.
      </AddressDetails>
    </Flex>
  );
};
