import { DoctorFragment, OfficeAddressFragment } from "~/types/graphql";

export function getFullPhysicalAddress(address: OfficeAddressFragment): string {
  return `${address.address1}, ${address.neighborhood}, CP:${address.zipcode}`;
}

export function getAddressUrl(address: OfficeAddressFragment): string {
  const searchAddress = `${address.address1} ${address.address2 || ""} ${
    address.neighborhood
  } ${address.city} ${address.zipcode}`;
  const formattedUrl = searchAddress
    .replace("#", "") // Remove # since is not supproted by the API
    .split(" ")
    .join("+");

  return address?.googleMapsUrl
    ? address.googleMapsUrl
    : `https://www.google.com/maps/search/?api=1&query=${formattedUrl}`;
}

export function hasTemporaryVirtualOffice(doctor: DoctorFragment): boolean {
  return !!(
    doctor.officeAddress?.temporaryVirtualOffice ||
    doctor.secondaryOfficeAddress?.temporaryVirtualOffice
  );
}
