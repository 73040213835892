import React from "react";
import { CSS, styled } from "~/styles/stitches.config";
import { Button, Grid, PaddedScreenContainer } from "~/components";
import Image from "next/image";

const gridCss: CSS = {
  mb: "$10",
  "@bp1": {
    marginBottom: 100,
  },
};

const ImageContainer = styled("div", {
  gridColumn: "1/-1",
  aspectRatio: "343/158",
  position: "relative",
  mb: "$4",
  "@bp1": {
    gridColumn: "6/span 7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const TextContainer = styled("div", {
  gridColumn: "1/-1",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: 8,
  "@bp1": {
    gridColumn: "2/span 4",
    gridRow: 1,
    textAlign: "left",
    gap: 16,
  },
});

const ButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 8,
  justifyContent: "center",
  alignItems: "center",
  mt: "$2",
  "@bp1": {
    gap: 24,
    justifyContent: "flex-start",
  },
});

const ImageAspectRatioCss = styled("div", {
  position: "relative",
  flex: 1,
  aspectRatio: "485/223",
  "@bp1": {
    maxWidth: 485,
  },
});

export const HomeJoin = () => {
  return (
    <PaddedScreenContainer>
      <Grid css={gridCss}>
        <ImageContainer>
          <ImageAspectRatioCss>
            <Image
              src={"/unete@2x.png"}
              alt={"unete"}
              fill
              quality={100}
              loading={"lazy"}
            />
          </ImageAspectRatioCss>
        </ImageContainer>
        <TextContainer>
          <h4>Únete a nuestro equipo</h4>
          <p>
            Únete a nuestra red de médicos y ayúdanos a mejorar la forma que las
            personas cuidan su salud.
          </p>
          <ButtonsContainer>
            <Button
              title={"Llenar solicitud"}
              onClick={() =>
                window.open(
                  "https://sofia-salud.typeform.com/to/ONtEoxFK?typeform-source=webflow.com#source=campaing_landing",
                )
              }
            />
            <Button
              title={"Conoce más"}
              type={"ghost"}
              onClick={() =>
                window.open("https://www.sofiasalud.com/unete-equipomedico")
              }
            />
          </ButtonsContainer>
        </TextContainer>
      </Grid>
    </PaddedScreenContainer>
  );
};
