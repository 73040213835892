import React, { ReactNode } from "react";
import { DoctorSpecialistDetailsFragment } from "~/types/graphql";
import * as Accordion from "@radix-ui/react-accordion";
import { keyframes, styled } from "~/styles/stitches.config";
import { ChevronDownIcon, SchoolIcon } from "@sofia-eng/web-icons";
import { AccordionTriggerProps as RadixAccordionTriggerProps } from "@radix-ui/react-accordion";

export interface DoctorProfileEducationProps {
  doctorSpecializations: DoctorSpecialistDetailsFragment[];
}

const SpecializationRow = styled("div", {
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "20px",
  color: "$blueGray200",
});

export const DoctorProfileEducation = ({
  doctorSpecializations,
}: DoctorProfileEducationProps) => {
  return (
    <AccordionRoot type={"single"} collapsible>
      <AccordionItem value={"item"}>
        <AccordionTrigger>
          <SchoolIcon size={"sm"} color={"primary500"} />
          <HeaderTitle>Educación</HeaderTitle>
        </AccordionTrigger>
        <AccordionContent>
          {doctorSpecializations.map(specialization => (
            <SpecializationRow key={specialization.id}>
              {`${specialization.medicalSpecialization.name} - ${specialization.specialistInstitution} C.P. ${specialization.specialistNumber}`}
            </SpecializationRow>
          ))}
        </AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  );
};

const AccordionRoot = styled(Accordion.Root, {
  borderRadius: 6,
  width: "100%",
  backgroundColor: "transparent",
  cursor: "pointer",
});

const AccordionItem = styled(Accordion.Item, {
  overflow: "hidden",
});

interface AccordionTriggerProps extends RadixAccordionTriggerProps {
  children?: ReactNode;
}

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledChevron aria-hidden>
        <ChevronDownIcon />
      </StyledChevron>
    </StyledTrigger>
  </StyledHeader>
));

const StyledHeader = styled(Accordion.Header, {
  all: "unset",
  display: "flex",
});

const HeaderTitle = styled("div", {
  flex: 1,
});

const StyledTrigger = styled(Accordion.Trigger, {
  all: "unset",
  fontFamily: "inherit",
  p: ["$2"],
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 8,
  fontSize: 16,
  lineHeight: "22px",
  fontWeight: 500,
  color: "$primary500",
  backgroundColor: "white",
});

const StyledChevron = styled("div", {
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const AccordionContent = styled(Accordion.Content, {
  overflow: "hidden",
  px: "$2",
  pb: "$2",

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});
