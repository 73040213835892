import React from "react";
import { PublicAddressType } from "~/types/graphql";
import { styled } from "~/styles/stitches.config";
import { PinLocationIcon, VideoConsultIcon } from "@sofia-eng/web-icons";
import { getFullPhysicalAddress } from "~/utils/office_address_utils";

export interface AddressItemProps {
  officeAddress: PublicAddressType;
}

const InfoRowIcon = styled("div", {
  display: "block",
  width: 16,
  height: 16,
});

const InfoRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 8,
  width: "100%",
});

const InfoRowColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "calc(100% - 24px)",
  gap: 4,
});

const InfoTitle = styled("p", {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "18px",
  color: "$blueGray200",
});

const InfoSubtitle = styled("p", {
  color: "$blueGray200",
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "20px",
  maxLines: 1,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
});

const AddressItem = ({ officeAddress }: AddressItemProps) => {
  return (
    <InfoRow>
      <InfoRowIcon>
        {officeAddress.temporaryVirtualOffice ? (
          <VideoConsultIcon color={"blueGray200"} />
        ) : (
          <PinLocationIcon color={"blueGray200"} />
        )}
      </InfoRowIcon>
      <InfoRowColumn>
        <InfoTitle>
          {officeAddress.temporaryVirtualOffice
            ? "Consulta en línea"
            : officeAddress.address2}
        </InfoTitle>
        <InfoSubtitle>
          {officeAddress.temporaryVirtualOffice
            ? "Toma una videoconsulta desde la comodidad de tu domicilio."
            : getFullPhysicalAddress(officeAddress)}
        </InfoSubtitle>
      </InfoRowColumn>
    </InfoRow>
  );
};

export default AddressItem;
