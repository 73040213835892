import React, { useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { DoctorFragment, useGetDoctorProfileQuery } from "~/types/graphql";
import { Dialog, DoctorProfileContent, Loader } from "~/components";

export interface DoctorProfileProps {
  uuid?: string;
  visible?: boolean;
  isMobile?: boolean;
  onDismiss?: VoidFunction;
  initialDoctor?: DoctorFragment;
}

export const DoctorProfile = ({
  visible = false,
  uuid,
  isMobile,
  onDismiss,
  initialDoctor,
}: DoctorProfileProps) => {
  const { refetch } = useGetDoctorProfileQuery({
    skip: true,
  });
  const [doctor, setDoctor] = useState<DoctorFragment | undefined>(
    initialDoctor,
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (uuid !== undefined && uuid.length > 0 && visible) {
      setError(false);
      setLoading(true);
      refetch({ uuid })
        .then(data => {
          setDoctor(data.data.publicDoctorProfile || undefined);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [refetch, uuid, visible]);

  if (isMobile) {
    return (
      <BottomSheet
        open={visible}
        onDismiss={onDismiss}
        defaultSnap={({ maxHeight }) => maxHeight * 0.6}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.6]}
        expandOnContentDrag={true}
      >
        {error ? (
          <h4>Error</h4>
        ) : loading ? (
          <Loader />
        ) : doctor ? (
          <DoctorProfileContent doctor={doctor} />
        ) : null}
      </BottomSheet>
    );
  } else {
    return (
      <Dialog open={visible} onDismiss={onDismiss}>
        {error ? (
          <h4>Error</h4>
        ) : loading ? (
          <Loader />
        ) : doctor ? (
          <DoctorProfileContent doctor={doctor} />
        ) : null}
      </Dialog>
    );
  }
};
