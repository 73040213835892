import { GetServerSideProps, NextPage } from "next";
import {
  DoctorFragment,
  GetMedicalSpecializationsDocument,
  GetMedicalSpecializationsQuery,
  GetMedicalSpecializationsQueryVariables,
  MedicalSpecializationFragment,
  PublicFilteredAddressDoctorProfileType,
  PublicPaginatedLocationBasedDoctorsDocument,
  PublicPaginatedLocationBasedDoctorsQuery,
  PublicPaginatedLocationBasedDoctorsQueryVariables,
  States,
  usePublicPaginatedLocationBasedDoctorsLazyQuery,
} from "~/types/graphql";
import { initializeApollo } from "~/server/apollo";
import {
  useDebouncedEffect,
  useList,
  useToggle,
  useUpdateEffect,
} from "@react-hookz/web";
import ListAppHeader from "~/components/list-app-header/ListAppHeader";
import ListHeader from "~/components/list-header/ListHeader";
import DoctorListItem from "~/components/doctor-list-item/DoctorListItem";
import { styled } from "~/styles/stitches.config";
import { getSelectorsByUserAgent } from "react-device-detect";
import { useRouter } from "next/router";
import {
  Button,
  DoctorProfile,
  DoctorsMap,
  Loader,
  NoResults,
} from "~/components";
import * as ToastR from "@radix-ui/react-toast";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import Flex from "~/components/flex/Flex";
import { StatesList, StatesTranslations } from "~/utils/states";
import { isSsr } from "~/utils/server";
import ListPagination from "~/components/list-pagination/ListPagination";

export const DOCTOR_ID_QUERY_VAR = "doctorId";
export const SPECIALIZATION_ID_QUERY_VAR = "specialization";
export const LOCATION_QUERY_VAR = "location";
export const SEARCH_QUERY_VAR = "busqueda";
export const PAGE_QUERY_VAR = "offest";
export const SIZE_QUERY_VAR = "size";

interface DoctorsListProps {
  medicalSpecializations: MedicalSpecializationFragment[];
  initialDoctors: (PublicFilteredAddressDoctorProfileType | null)[];
  totalCount?: number | undefined;
  isMobile?: boolean;
}

interface ToastProviderProps {
  children?: ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) return null;
  return <ToastR.Provider label={"Notification"}>{children}</ToastR.Provider>;
};

const Main = styled("main", {
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  maxHeight: "100%",
  height: "100%",
  backgroundColor: "#FAFAFA",
  "@bp1": {
    width: "100%",
    overflow: "hidden",
    alignItems: "center",
  },
});

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  flexGrow: 1,
  overflow: "scroll",
  justifySelf: "stretch",
  "@bp1": {
    flexGrow: 1,
    flexShrink: 0,
    maxWidth: 1080,
    width: "100%",
    flexDirection: "row",
    overflow: "visible",
    height: "calc(100% - 115px)",
  },
});

const ListContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  flexGrow: 1,
  overflowY: "scroll",
  justifySelf: "stretch",
  "@bp1": {
    flexGrow: 1,
    flexShrink: 0,
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    justifySelf: "flex-start",
    width: "50%",
    pt: "$4",
  },
});

const ListInnerContainer = styled("div", {
  padding: 16,
  "@bp1": {
    flex: 1,
    padding: 0,
    paddingBottom: 32,
    overflowY: "scroll",
  },
});

const List = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  "@bp1": {
    overflowY: "hidden",
  },
});

const MapButtonContainer = styled(Flex, {
  position: "fixed",
  zIndex: 3,
  width: "100%",
  bottom: 0,
  left: 0,
  pointerEvents: "none",
  height: 85,
  background:
    "linear-gradient(360deg, #FFFFFF 15.91%, rgba(255, 255, 255, 0.1) 88.07%)",
  "@bp1": {
    display: "none",
  },
});

const DoctorsList: NextPage<DoctorsListProps> = ({
  medicalSpecializations,
  initialDoctors,
  totalCount,
  isMobile,
}) => {
  const router = useRouter();
  const queryVariables = router.query;
  const doctorId = queryVariables[DOCTOR_ID_QUERY_VAR] as string | undefined;
  const specializationName = queryVariables[SPECIALIZATION_ID_QUERY_VAR] as
    | string
    | undefined;
  const location = queryVariables[LOCATION_QUERY_VAR] as States | undefined;
  const searchTerm =
    (queryVariables[SEARCH_QUERY_VAR] as string | undefined) ?? "";
  const size = queryVariables[SIZE_QUERY_VAR]
    ? +queryVariables[SIZE_QUERY_VAR]
    : 10;
  const filterValidDoctors = (
    doctors: (PublicFilteredAddressDoctorProfileType | null | undefined)[],
  ): PublicFilteredAddressDoctorProfileType[] => {
    return doctors.filter(
      (doctor): doctor is PublicFilteredAddressDoctorProfileType =>
        doctor !== null && doctor !== undefined,
    );
  };
  const [doctors, doctorsActions] =
    useList<PublicFilteredAddressDoctorProfileType>(
      filterValidDoctors(initialDoctors),
    );
  const [loading, setLoading] = useState<boolean>(false);
  const [getDoctorQuery, { refetch: getDoctors, data, called }] =
    usePublicPaginatedLocationBasedDoctorsLazyQuery();
  const [mapOpened, setMapOpened] = useToggle(false);
  const [selectedPin, setSelectedPin] = useState<string>();
  const [hoveredDoctor, setHoveredDoctor] = useState<string>();
  const [page, setPage] = useState<number>(
    queryVariables[PAGE_QUERY_VAR]
      ? Math.ceil(+queryVariables[PAGE_QUERY_VAR] / size)
      : 0,
  );
  const [doctorsAmount, setDoctorsAmount] = useState<number | undefined>(
    totalCount,
  );
  const isEmpty = doctorsAmount === 0;

  const specialtyName = medicalSpecializations.find(
    specialization => specialization.name === specializationName,
  )?.name;

  const stateName = location ? StatesTranslations[location] : undefined;

  const previousSpecializationName = useRef(specializationName);
  const previousLocation = useRef(stateName);
  const previousName = useRef(searchTerm);

  const setDoctorsData = (
    data: PublicPaginatedLocationBasedDoctorsQuery | undefined,
  ) => {
    const doctors = data?.publicPaginatedLocationBasedDoctors?.doctors;
    if (doctors) {
      const filteredDoctors = filterValidDoctors(doctors);
      doctorsActions.set(filteredDoctors);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useUpdateEffect(() => {
    setLoading(true);
    setSelectedPin(undefined);
    const vars: PublicPaginatedLocationBasedDoctorsQueryVariables = {
      locationInput:
        stateName && (stateName as string) !== "TODOS" ? { stateName } : null,
      medicalSpecializationName: specializationName,
      virtualOffice: false,
      name: searchTerm,
      count: size,
      offset: page * size,
    };
    if (
      previousSpecializationName.current !== specializationName ||
      previousLocation.current !== stateName ||
      previousName.current !== searchTerm
    ) {
      vars.offset = 0;
      setPage(0);
    }
    if (!called) {
      getDoctorQuery({ variables: vars });
    } else {
      getDoctors(vars);
    }
    previousSpecializationName.current = specializationName;
    previousLocation.current = stateName;
    previousName.current = searchTerm;
  }, [specializationName, stateName, searchTerm, size, page]);

  useUpdateEffect(() => {
    setLoading(false);
    setDoctorsData(data);
    setDoctorsAmount(
      data?.publicPaginatedLocationBasedDoctors?.totalCount ?? 0,
    );
  }, [data]);

  useUpdateEffect(() => {
    if (isEmpty && loading) {
      setLoading(false);
    }
  }, [isEmpty, loading]);

  useDebouncedEffect(
    () => {
      router.push(
        {
          query: {
            ...router.query,
            [PAGE_QUERY_VAR]: page * size,
            [SIZE_QUERY_VAR]: size,
          },
        },
        undefined,
        { shallow: true },
      );
    },
    [page],
    0,
  );

  const onDismissDoctorProfile = () => {
    setDoctorId(undefined);
  };

  const onClickDoctor = (uuid: string) => {
    setDoctorId(uuid);
  };

  const setDoctorId = (uuid: string | undefined) => {
    router.push(
      {
        query: {
          ...router.query,
          [DOCTOR_ID_QUERY_VAR]: uuid,
        },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  return (
    <ToastProvider>
      <Main>
        <ListAppHeader
          medicalSpecializations={medicalSpecializations}
          isMobile={isMobile}
          initialSpeciality={specializationName}
          initialState={location}
        />
        <MainContainer>
          <ListContainer>
            <ListHeader
              entitiesAmount={doctorsAmount}
              initialSearchTerm={searchTerm}
              location={stateName}
              specialization={specialtyName}
            />
            {loading ? (
              <Loader />
            ) : isEmpty ? (
              <NoResults />
            ) : (
              <ListInnerContainer>
                <List>
                  {doctors.map((doctor, index) => (
                    <DoctorListItem
                      key={`${doctor?.doctor?.uuid}-${index}`}
                      doctor={doctor?.doctor as DoctorFragment}
                      onClick={() => onClickDoctor(doctor?.doctor?.uuid ?? "")}
                      onHover={() => setHoveredDoctor(doctor?.doctor?.uuid)}
                      onHoverOut={() => setHoveredDoctor(undefined)}
                    />
                  ))}
                </List>
                <ListPagination
                  currentPage={page}
                  totalPages={
                    doctorsAmount ? Math.ceil(doctorsAmount / size) : 0
                  }
                  onPageChange={handlePageChange}
                />
              </ListInnerContainer>
            )}
          </ListContainer>
          <DoctorsMap
            doctors={doctors.map(doctor => doctor?.doctor as DoctorFragment)}
            opened={mapOpened}
            selectedPin={selectedPin}
            setSelectedPin={setSelectedPin}
            hoveredDoctor={hoveredDoctor}
          />
        </MainContainer>
        {isSsr ? null : (
          <DoctorProfile
            uuid={doctorId}
            isMobile={isMobile}
            visible={!!doctorId}
            onDismiss={onDismissDoctorProfile}
          />
        )}
        {!isEmpty && !loading ? (
          <MapButtonContainer justify={"center"} align={"center"}>
            <Button
              title={mapOpened ? "Mostrar lista" : "Mostrar mapa"}
              onClick={setMapOpened}
            />
          </MapButtonContainer>
        ) : null}
      </Main>
      <ToastViewport />
    </ToastProvider>
  );
};

const VIEWPORT_PADDING = 25;

const ToastViewport = styled(ToastR.Viewport, {
  position: "fixed",
  top: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 390,
  maxWidth: "100vw",
  margin: 0,
  listStyle: "none",
  zIndex: 2147483647,
  outline: "none",
});

export default DoctorsList;

export const getServerSideProps: GetServerSideProps<
  DoctorsListProps
> = async ctx => {
  const client = initializeApollo({ ctx });
  const userAgent = ctx.req.headers["user-agent"] ?? "";
  const { isMobile } = getSelectorsByUserAgent(userAgent);
  try {
    const medicalSpecializations = await client.query<
      GetMedicalSpecializationsQuery,
      GetMedicalSpecializationsQueryVariables
    >({
      query: GetMedicalSpecializationsDocument,
      variables: {
        inCatalog: true,
      },
    });

    const state = ctx.query[LOCATION_QUERY_VAR]
      ? ctx.query[LOCATION_QUERY_VAR] === "TODOS"
        ? undefined
        : (ctx.query[LOCATION_QUERY_VAR] as States | undefined)
      : undefined;

    const size = ctx.query[SIZE_QUERY_VAR] ? +ctx.query[SIZE_QUERY_VAR] : 10;

    const stateName = StatesList().find(item => item.value === state);

    const variables = {
      medicalSpecializationName: ctx.query[SPECIALIZATION_ID_QUERY_VAR]
        ? (ctx.query[SPECIALIZATION_ID_QUERY_VAR] as string)
        : undefined,
      locationInput:
        state && (state as string) !== "TODOS"
          ? { stateName: stateName?.label as States }
          : null,
      virtualOffice: false,
      name: ctx.query[SEARCH_QUERY_VAR]
        ? (ctx.query[SEARCH_QUERY_VAR] as string | undefined)
        : undefined,
      count: size,
      offset: ctx.query[PAGE_QUERY_VAR] ? +ctx.query[PAGE_QUERY_VAR] : 0,
    };

    const doctors = await client.query<
      PublicPaginatedLocationBasedDoctorsQuery,
      PublicPaginatedLocationBasedDoctorsQueryVariables
    >({
      query: PublicPaginatedLocationBasedDoctorsDocument,
      variables,
    });

    return {
      props: {
        medicalSpecializations: [
          ...(medicalSpecializations.data
            .publicMedicalSpecializations as MedicalSpecializationFragment[]),
        ].sort((a, b) => a.name.localeCompare(b.name)),
        initialDoctors:
          doctors.data.publicPaginatedLocationBasedDoctors?.doctors ?? [],
        totalCount:
          doctors.data.publicPaginatedLocationBasedDoctors?.totalCount ??
          undefined,
        isMobile,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      props: {
        medicalSpecializations: [],
        initialDoctors: [],
        totalCount: 0,
        isMobile,
      },
    };
  }
};
