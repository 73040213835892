import React, { ReactNode } from "react";
import { styled } from "~/styles/stitches.config";

export interface FooterLinkData {
  text: string;
  href: string;
}

export interface FooterLinkBlockProps {
  title: string;
  links?: FooterLinkData[];
  children?: ReactNode;
}

const LinkBlock = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: 28,
  "& p": {
    color: "$blueGray300",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "24px",
    marginBottom: 8,
  },
  "& a": {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "@bp1": {
    width: "auto",
  },
});

const LinkBlockLinks = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

export const FooterLinkBlock = ({
  links,
  title,
  children,
}: FooterLinkBlockProps) => {
  return (
    <LinkBlock>
      <p>{title}</p>
      <LinkBlockLinks>
        {links?.map((link, index) => (
          <a
            key={`link-${index}`}
            href={link.href}
            referrerPolicy={"no-referrer"}
          >
            {link.text}
          </a>
        ))}
      </LinkBlockLinks>
      {children}
    </LinkBlock>
  );
};
