import React from "react";
import { keyframes, styled } from "~/styles/stitches.config";

const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  flex: 1,
  "@bp1": {
    minHeight: 200,
  },
});

const Eases = {
  backIn: "cubic-bezier(0.6, -0.28, 0.735, 0.045)",
  circOut: "cubic-bezier(0.075, 0.82, 0.165, 1)",
  circIn: "cubic-bezier(0.6, 0.04, 0.98, 0.335)",
  circInOut: "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
};

const rotateScale = keyframes({
  "0%": {
    transform: "rotate(0)",
    width: 50,
    animationTimingFunction: Eases.backIn,
  },
  "50%": {
    transform: "rotate(360deg)",
    width: 50,
  },
  "51%": { width: 50, transform: "rotate(360deg)" },
  "55%": {
    width: 50,
    transform: "rotate(360deg)",
    animationTimingFunction: Eases.circInOut,
  },
  "75%": { width: 25, transform: "rotate(360deg)" },
  "80%": {
    width: 25,
    transform: "rotate(360deg)",
    animationTimingFunction: Eases.circOut,
  },
  "100%": { width: 50, transform: "rotate(360deg)" },
});

const LoaderIcon = styled("div", {
  width: 50,
  height: 25,
  borderRadius: 100,
  backgroundColor: "$primary500",
  display: "block",
  animation: `${rotateScale} 2.75s`,
  animationIterationCount: "infinite",
});

export const Loader = () => {
  return (
    <Container>
      <LoaderIcon />
    </Container>
  );
};
