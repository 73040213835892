import React, { useState } from "react";
import { MedicalSpecializationFragment, States } from "~/types/graphql";
import { styled } from "~/styles/stitches.config";
import { Button, HeaderSelect, HeaderSelectOption } from "~/components";
import { StatesList, StatesTranslations } from "~/utils/states";
import { useRouter } from "next/router";
import {
  LOCATION_QUERY_VAR,
  PAGE_QUERY_VAR,
  SIZE_QUERY_VAR,
  SPECIALIZATION_ID_QUERY_VAR,
} from "~/pages/lista";
import { SearchIcon } from "@sofia-eng/web-icons";

export interface HomeFiltersProps {
  medicalSpecializations: MedicalSpecializationFragment[];
  isMobile?: boolean;
}

const MainContainer = styled("div", {
  px: "$2",
  width: "100%",
  "@bp1": {
    width: 735,
  },
});

const Container = styled("div", {
  p: ["$2"],
  borderRadius: 16,
  backgroundColor: "$primary50",
  width: "100%",
  mt: "$4",
  "@bp1": {
    borderRadius: 100,
    mt: "$8",
  },
});

const Content = styled("div", {
  backgroundColor: "$white",
  borderRadius: 16,
  p: ["$2"],
  boxShadow: "0px 3px 8px 0px #E9E9E9",
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  gap: 16,
  "@bp1": {
    pl: "$4",
    borderRadius: 100,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

const SelectorsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 32,
  "@bp1": {
    flexDirection: "row",
    justifySelf: "stretch",
    flex: 1,
  },
});

const MobileButton = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  [`$ ${Button}`]: {
    width: "100%",
  },
  "@bp1": {
    display: "none",
  },
});

const DesktopButton = styled("div", {
  display: "none",
  "@bp1": {
    display: "flex",
    width: 56,
    height: 56,
    borderRadius: 100,
    backgroundColor: "$primary500",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "$primary700",
    },
  },
});

export const HomeFilters = ({
  medicalSpecializations,
  isMobile,
}: HomeFiltersProps) => {
  const router = useRouter();
  const [specialty, setSpecialty] = useState<string>();
  const [state, setState] = useState<string>();

  const onFilterDoctors = () => {
    router.push({
      pathname: "/lista",
      query: {
        [SPECIALIZATION_ID_QUERY_VAR]: specialty,
        [LOCATION_QUERY_VAR]: state,
        [PAGE_QUERY_VAR]: 0,
        [SIZE_QUERY_VAR]: 10,
      },
    });
  };

  return (
    <MainContainer>
      <Container>
        <Content>
          <SelectorsContainer>
            <HeaderSelect
              value={specialty}
              onChange={setSpecialty}
              label={"Especialidad"}
              options={medicalSpecializations.map(specialization => {
                return {
                  value: specialization.name,
                  label: specialization.name,
                  subTitle: specialization.description,
                };
              })}
              width={484}
              isMobile={isMobile}
            />
            <HeaderSelect
              value={state}
              onChange={setState}
              options={StatesList()}
              label={"Localización"}
              isMobile={isMobile}
              width={256}
            />
          </SelectorsContainer>
          <MobileButton>
            <Button title={"Buscar doctor/a"} onClick={onFilterDoctors} block />
          </MobileButton>
          <DesktopButton onClick={onFilterDoctors}>
            <SearchIcon color={"white"} size={"md"} />
          </DesktopButton>
        </Content>
      </Container>
    </MainContainer>
  );
};
