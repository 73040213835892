import React from "react";
import { styled } from "~/styles/stitches.config";
import { Button } from "~/components";
import { useRouter } from "next/router";

const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  flex: 1,
  "@bp1": {
    paddingBottom: 32,
  },
});

const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 24,
  maxWidth: 311,
  textAlign: "center",

  "@bp1": {
    maxWidth: "100%",
  },

  "& .title": {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
  },

  "& .description": {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
  },

  "& a": {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 700,
  },
});

export const NoResults = ({ isProvider }: { isProvider?: boolean }) => {
  const router = useRouter();

  const onBackHandler = () => {
    router.push("/");
  };

  return (
    <Container>
      <Content>
        <svg
          fill="none"
          height="153"
          viewBox="0 0 153 153"
          width="153"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="#4a25aa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.3"
          >
            <path
              d="m24.2469 96.9122c-8.8522 8.8488-8.8556 23.1978-.0077 32.0488s23.1967 8.853 32.0488.004l72.696-72.6713c8.852-8.849 8.856-23.1978.008-32.0488s-23.197-8.8526-32.0489-.0035z"
              fill="#fff"
            />
            <g fill="#ede9f7">
              <path d="m119.111 46.1277c1.049 0 1.9-.8507 1.9-1.9s-.851-1.9-1.9-1.9-1.9.8507-1.9 1.9.851 1.9 1.9 1.9z" />
              <path d="m109.129 36.1467c1.05 0 1.9-.8506 1.9-1.9 0-1.0493-.85-1.9-1.9-1.9-1.049 0-1.9.8507-1.9 1.9 0 1.0494.851 1.9 1.9 1.9z" />
              <path d="m108.667 46.4364c1.049 0 1.9-.8506 1.9-1.9 0-1.0493-.851-1.9-1.9-1.9-1.05 0-1.9.8507-1.9 1.9 0 1.0494.85 1.9 1.9 1.9z" />
              <path d="m98.692 36.4555c1.0493 0 1.9-.8506 1.9-1.9 0-1.0493-.8507-1.9-1.9-1.9-1.0494 0-1.9.8507-1.9 1.9 0 1.0494.8506 1.9 1.9 1.9z" />
              <path d="m118.648 56.4174c1.049 0 1.9-.8507 1.9-1.9 0-1.0494-.851-1.9-1.9-1.9s-1.9.8506-1.9 1.9c0 1.0493.851 1.9 1.9 1.9z" />
              <path d="m97.9195 57.1892c1.0494 0 1.9-.8506 1.9-1.9 0-1.0493-.8506-1.9-1.9-1.9-1.0493 0-1.9.8507-1.9 1.9 0 1.0494.8507 1.9 1.9 1.9z" />
              <path d="m87.9449 47.2083c1.0494 0 1.9-.8506 1.9-1.9 0-1.0493-.8506-1.9-1.9-1.9-1.0493 0-1.9.8507-1.9 1.9 0 1.0494.8507 1.9 1.9 1.9z" />
              <path d="m107.901 67.1702c1.049 0 1.9-.8507 1.9-1.9 0-1.0494-.851-1.9-1.9-1.9s-1.9.8506-1.9 1.9c0 1.0493.851 1.9 1.9 1.9z" />
              <path d="m108.364 56.8805c1.049 0 1.9-.8507 1.9-1.9s-.851-1.9-1.9-1.9-1.9.8507-1.9 1.9.851 1.9 1.9 1.9z" />
              <path d="m98.3834 46.8996c1.0493 0 1.8996-.8507 1.8996-1.9001 0-1.0493-.8503-1.9-1.8996-1.9s-1.9.8507-1.9 1.9c0 1.0494.8507 1.9001 1.9 1.9001z" />
            </g>
            <path
              d="m98.6396 69.8749-15.2797-15.285c-1.8222-1.8228-4.7771-1.8233-6.5999-.0011l-22.1423 22.1345c-1.8228 1.8222-1.8233 4.7771-.0011 6.6l15.2796 15.285c1.8223 1.8227 4.7772 1.8237 6.6.0011l22.1423-22.1345c1.8225-1.8222 1.8235-4.7771.0011-6.6z"
              fill="#a592d5"
            />
            <path
              d="m34.1334 110.87c1.0493 0 1.9-.85 1.9-1.9 0-1.049-.8507-1.9-1.9-1.9s-1.9.851-1.9 1.9c0 1.05.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m44.1139 120.857c1.0493 0 1.9-.851 1.9-1.9s-.8507-1.9-1.9-1.9c-1.0494 0-1.9.851-1.9 1.9s.8506 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m44.5777 110.561c1.0494 0 1.9-.85 1.9-1.9 0-1.049-.8506-1.9-1.9-1.9-1.0493 0-1.9.851-1.9 1.9 0 1.05.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m54.5523 120.548c1.0494 0 1.9-.85 1.9-1.9 0-1.049-.8506-1.9-1.9-1.9-1.0493 0-1.9.851-1.9 1.9 0 1.05.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m34.5963 100.581c1.0493 0 1.9-.8511 1.9-1.9005 0-1.0493-.8507-1.9-1.9-1.9-1.0494 0-1.9.8507-1.9 1.9 0 1.0494.8506 1.9005 1.9 1.9005z"
              fill="#ede9f7"
            />
            <path
              d="m55.3248 99.8087c1.0493 0 1.9-.8507 1.9-1.9 0-1.0494-.8507-1.9-1.9-1.9s-1.9.8506-1.9 1.9c0 1.0493.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m65.2994 109.796c1.0494 0 1.9-.851 1.9-1.9 0-1.05-.8506-1.9-1.9-1.9-1.0493 0-1.9.85-1.9 1.9 0 1.049.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m45.3434 89.8277c1.0493 0 1.9-.8506 1.9-1.9 0-1.0493-.8507-1.9-1.9-1.9-1.0494 0-1.9.8507-1.9 1.9 0 1.0494.8506 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m44.8805 100.117c1.0493 0 1.9-.8503 1.9-1.8996 0-1.0494-.8507-1.9-1.9-1.9-1.0494 0-1.9.8506-1.9 1.9 0 1.0493.8506 1.8996 1.9 1.8996z"
              fill="#ede9f7"
            />
            <path
              d="m54.8609 110.104c1.0494 0 1.9-.85 1.9-1.9 0-1.049-.8506-1.9-1.9-1.9-1.0493 0-1.9.851-1.9 1.9 0 1.05.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m56.2901 24.2492c-8.8521-8.8491-23.2009-8.8475-32.0488.0035s-8.8445 23.1998.0077 32.0488l72.6962 72.6705c8.8518 8.849 23.2008 8.848 32.0488-.003s8.844-23.2-.008-32.049z"
              fill="#fff"
            />
            <path
              d="m34.1334 46.1277c1.0493 0 1.9-.8507 1.9-1.9s-.8507-1.9-1.9-1.9-1.9.8507-1.9 1.9.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m44.1139 36.1467c1.0493 0 1.9-.8506 1.9-1.9 0-1.0493-.8507-1.9-1.9-1.9-1.0494 0-1.9.8507-1.9 1.9 0 1.0494.8506 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m44.5777 46.4364c1.0494 0 1.9-.8506 1.9-1.9 0-1.0493-.8506-1.9-1.9-1.9-1.0493 0-1.9.8507-1.9 1.9 0 1.0494.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m54.5523 36.4555c1.0494 0 1.9-.8506 1.9-1.9 0-1.0493-.8506-1.9-1.9-1.9-1.0493 0-1.9.8507-1.9 1.9 0 1.0494.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m34.5963 56.4174c1.0493 0 1.9-.8507 1.9-1.9 0-1.0494-.8507-1.9-1.9-1.9-1.0494 0-1.9.8506-1.9 1.9 0 1.0493.8506 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m55.3248 57.1892c1.0493 0 1.9-.8506 1.9-1.9 0-1.0493-.8507-1.9-1.9-1.9s-1.9.8507-1.9 1.9c0 1.0494.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m65.2994 47.2083c1.0494 0 1.9-.8506 1.9-1.9 0-1.0493-.8506-1.9-1.9-1.9-1.0493 0-1.9.8507-1.9 1.9 0 1.0494.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m45.3434 67.1702c1.0493 0 1.9-.8507 1.9-1.9 0-1.0494-.8507-1.9-1.9-1.9-1.0494 0-1.9.8506-1.9 1.9 0 1.0493.8506 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m44.8805 56.8805c1.0493 0 1.9-.8507 1.9-1.9s-.8507-1.9-1.9-1.9c-1.0494 0-1.9.8507-1.9 1.9s.8506 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m54.8609 46.8995c1.0494 0 1.9-.8506 1.9-1.9 0-1.0493-.8506-1.9-1.9-1.9-1.0493 0-1.9.8507-1.9 1.9 0 1.0494.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m83.3535 98.6021 15.2797-15.285c1.8218-1.8229 1.8218-4.7778-.0011-6.6l-22.1423-22.1345c-1.8229-1.8222-4.7778-1.8217-6.6.0011l-15.2797 15.2851c-1.8222 1.8228-1.8216 4.7777.0012 6.5999l22.1423 22.1346c1.8228 1.8217 4.7777 1.8217 6.5999-.0012z"
              fill="#a592d5"
            />
            <path
              d="m119.111 110.87c1.049 0 1.9-.851 1.9-1.9s-.851-1.9-1.9-1.9-1.9.851-1.9 1.9.851 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m109.129 120.857c1.05 0 1.9-.851 1.9-1.9s-.85-1.9-1.9-1.9c-1.049 0-1.9.851-1.9 1.9s.851 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m108.667 110.561c1.049 0 1.9-.85 1.9-1.9 0-1.049-.851-1.9-1.9-1.9-1.05 0-1.9.851-1.9 1.9 0 1.05.85 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m98.692 120.548c1.0493 0 1.9-.85 1.9-1.9 0-1.049-.8507-1.9-1.9-1.9-1.0494 0-1.9.851-1.9 1.9 0 1.05.8506 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m118.648 100.58c1.049 0 1.9-.8502 1.9-1.8995 0-1.0494-.851-1.9-1.9-1.9s-1.9.8506-1.9 1.9c0 1.0493.851 1.8995 1.9 1.8995z"
              fill="#ede9f7"
            />
            <path
              d="m97.9195 99.8086c1.0494 0 1.9-.8507 1.9-1.9s-.8506-1.9-1.9-1.9c-1.0493 0-1.9.8507-1.9 1.9s.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m87.9449 109.795c1.0494 0 1.9-.85 1.9-1.9 0-1.049-.8506-1.9-1.9-1.9-1.0493 0-1.9.851-1.9 1.9 0 1.05.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m107.901 89.8276c1.049 0 1.9-.8506 1.9-1.9 0-1.0493-.851-1.9-1.9-1.9s-1.9.8507-1.9 1.9c0 1.0494.851 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
            <path
              d="m108.364 100.117c1.049 0 1.9-.8503 1.9-1.8997 0-1.0493-.851-1.9-1.9-1.9s-1.9.8507-1.9 1.9c0 1.0494.851 1.8997 1.9 1.8997z"
              fill="#ede9f7"
            />
            <path
              d="m98.3834 110.104c1.0493 0 1.8996-.85 1.8996-1.9 0-1.049-.8503-1.9-1.8996-1.9s-1.9.851-1.9 1.9c0 1.05.8507 1.9 1.9 1.9z"
              fill="#ede9f7"
            />
          </g>
        </svg>
        <div>
          <p style={{ fontSize: 16, lineHeight: 1.2 }}>
            ¡Ups! No encontramos {isProvider ? "hospitales" : "doctores"}
            <br />
            Puedes intentar con otra ubicación o consultar a los Sabi@s desde
            nuestra app
          </p>
        </div>
        <Button title={"Volver al inicio"} onClick={onBackHandler} />
      </Content>
    </Container>
  );
};
