import { createStitches } from "@stitches/react";
import type * as Stitches from "@stitches/react";
import { Colors } from "@sofia-eng/design-system-core";

export type Space = Stitches.ScaleValue<"space">;
export const BP1_SIZE = 640;
export const BP2_SIZE = 768;
export const BP3_SIZE = 1024;

const stitches = createStitches({
  theme: {
    /** @see {@link https://bit.ly/3yAcKm6} color palette */
    colors: {
      ...Colors,
      linen: "#FCF7F2",
      primary700: "#29127A",
    },
    space: {
      1: `${8}px`,
      2: `${8 * 2}px`,
      3: `${8 * 3}px`,
      4: `${8 * 4}px`,
      5: `${8 * 5}px`,
      6: `${8 * 6}px`,
      7: `${8 * 7}px`,
      8: `${8 * 8}px`,
      9: `${8 * 9}px`,
      10: `${8 * 10}px`,
    },
    radii: {
      xxs: "2px",
      xs: "8px",
      md: "10px",
      lg: "16px",
      xl: "24px",
      round: "50%",
      pill: "9999px",
    },
    fontSizes: {
      caption: "12px",
      body2: "14px",
      body1: "16px",
      sub: "20px",
      body: "17px",
      button: "18px",
      h4: "24px",
      h3: "30px",
      h2: "38px",
      h1: "46px",
    },
    lineHeights: {
      caption: "20px",
      body2: "22px",
      body1: "24px",
      sub: "28px",
      button: "22px",
      body: "24px",
      h4: "32px",
      h3: "38px",
      h2: "46px",
      h1: "54px",
    },
    fontWeights: {
      thin: "100",
      extralight: "200",
      light: "300",
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
      extrabold: "800",
      black: "900",
    },
    borderWidths: {
      sm: "1.5px",
      md: "2px",
    },
    zIndices: {
      modal: "100",
    },
    shadows: {
      focusOutline: "0 0 0 2px $colors$purple100",
      shadow1: "0px 3px 8px 0px #E9E9E9",
    },
  },
  media: {
    bp1: `(min-width: ${BP1_SIZE}px)`,
    bp2: `(min-width: ${BP2_SIZE}px)`,
    bp3: `(min-width: ${BP3_SIZE}px)`,
  },
  utils: {
    /** Padding */
    p: (values: [Space, Space?, Space?, Space?]) => ({
      padding: values.map(value => (value ? value : "")).join(" "),
    }),
    /** Padding Top */
    pt: (value: Space) => ({
      paddingTop: value,
    }),
    /** Padding Right */
    pr: (value: Space) => ({
      paddingRight: value,
    }),
    /** Padding Bottom */
    pb: (value: Space) => ({
      paddingBottom: value,
    }),
    /** Padding Left */
    pl: (value: Space) => ({
      paddingLeft: value,
    }),
    /** Padding X */
    px: (value: Space) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    /** Padding Y */
    py: (value: Space) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    /** Margin X */
    mx: (value: Space) => ({
      marginLeft: value,
      marginRight: value,
    }),
    /** Margin Bottom */
    mb: (value: Space) => ({
      marginBottom: value,
    }),
    /** Margin Top */
    mt: (value: Space) => ({
      marginTop: value,
    }),
    /** Margin Right */
    mr: (value: Space) => ({
      marginRight: value,
    }),
    size: (value: number | string) => ({
      width: value,
      height: value,
    }),
  },
});

export type CSS = Stitches.CSS<typeof stitches>;
export const { styled, css, theme, getCssText, globalCss, keyframes } =
  stitches;
