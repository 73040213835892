import React, { ChangeEvent, useState } from "react";
import { styled } from "~/styles/stitches.config";
import { useDebouncedEffect, useMountEffect } from "@react-hookz/web";
import { useRouter } from "next/router";
import { PAGE_QUERY_VAR, SEARCH_QUERY_VAR } from "~/pages/lista";
import { ArrowLeftIcon, SearchIcon } from "@sofia-eng/web-icons";

export interface ListHeaderProps {
  initialSearchTerm?: string;
  entitiesAmount?: number;
  location?: string;
  specialization?: string;
  isProviderFlow?: boolean;
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  py: "$3",
  px: "$2",
  "@bp1": {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    pb: "$4",
  },
});

const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  py: "$3",
  px: "$2",
  "@bp1": {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    gap: 12,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    alignItems: "center",
  },
});

const InputContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  borderBottom: "1px solid $blueGray75",
  gap: 8,
  alignItems: "center",
  justifyContent: "flex-start",
  "@bp1": {
    height: 24,
    width: 200,
  },
});

const Input = styled("input", {
  flex: 1,
  border: "none",
  outline: "none",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "20px",
  color: "$blueGray300",
  backgroundColor: "$transparent",
  "&::placeholder": {
    color: "$blueGray200",
  },
});

const BackButton = styled(ArrowLeftIcon, {
  cursor: "pointer",
});

const ListHeader = ({
  initialSearchTerm = "",
  entitiesAmount,
  location,
  specialization,
  isProviderFlow,
}: ListHeaderProps) => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useMountEffect(() => {
    setSearchTerm(initialSearchTerm);
  });

  useDebouncedEffect(
    () => {
      router.push(
        {
          query: {
            ...router.query,
            [PAGE_QUERY_VAR]: 0,
            [SEARCH_QUERY_VAR]: searchTerm,
          },
        },
        undefined,
        { shallow: true },
      );
    },
    [searchTerm],
    750,
  );

  const onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onBackHandler = () => {
    router.push("/");
  };

  return (
    <Container>
      <BackButton size={"lg"} color={"purple500"} onClick={onBackHandler} />
      <Content>
        <InputContainer>
          <SearchIcon size={"sm"} color={"purple500"} />
          <Input
            value={searchTerm}
            onChange={onChangeSearchInput}
            placeholder={`Nombre del ${
              isProviderFlow ? "Hospital" : "doctor/a"
            }`}
          />
        </InputContainer>
        <div>
          <strong>{entitiesAmount}</strong>
          {` ${isProviderFlow ? "Hospitales" : "especialistas"} ${
            !isProviderFlow
              ? specialization
                ? `en ${specialization}`
                : "en todas las especialidades"
              : ""
          } en ${location ? location : "todos los estados"}`}
        </div>
      </Content>
    </Container>
  );
};

export default ListHeader;
