import React from "react";
import { CSS, styled } from "~/styles/stitches.config";
import { Grid, HomeFilters, PaddedScreenContainer } from "~/components";
import { MedicalSpecializationFragment } from "~/types/graphql";

const Container = styled("div", {
  width: "100%",
  backgroundColor: "$linen",
  borderRadius: "0px 0px 32px 32px",
  py: "$6",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  mb: "$10",
  "& h2": {
    fontSize: 32,
    fontWeight: 700,
    textAlign: "center",
    lineHeight: "40px",
    gridColumn: "1/-1",
    mb: "$2",
  },
  "& p": {
    textAlign: "center",
    gridColumn: "1/-1",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "24px",
  },
  "@bp1": {
    marginBottom: 100,
    "& h2": {
      fontSize: 48,
      lineHeight: "56px",
    },
    "& p": {
      fontSize: 24,
      lineHeight: "32px",
    },
  },
});

const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
});

const Title = styled("div", {
  display: "block",
  padding: "10px 24px",
  color: "$white",
  backgroundColor: "$primary200",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  borderRadius: 100,
  mb: "$2",
  "@bp1": {
    fontSize: 24,
    lineHeight: "32px",
  },
});

const gridCss: CSS = {
  justifyItems: "flex-start",
  alignItems: "center",
  width: "100%",
  "@bp1": {
    justifyItems: "center",
  },
};

export interface HeroProps {
  doctorsAmount: number;
  medicalSpecializations: MedicalSpecializationFragment[];
  isMobile?: boolean;
}

export const Hero = ({
  medicalSpecializations,
  doctorsAmount,
  isMobile,
}: HeroProps) => {
  return (
    <Container>
      <PaddedScreenContainer>
        <Content>
          <Title>
            <span>Directorio SofíaMed</span>
          </Title>
          <Grid css={gridCss}>
            <h2>¡Encuentra el o la doctora que necesitas!</h2>
            <p>
              Contamos con <strong>{doctorsAmount}</strong> doctores en{" "}
              <strong>{medicalSpecializations.length}</strong> diferentes
              especialidades.
            </p>
          </Grid>
          <HomeFilters
            medicalSpecializations={medicalSpecializations}
            isMobile={isMobile}
          />
        </Content>
      </PaddedScreenContainer>
    </Container>
  );
};
