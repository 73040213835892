import React from "react";
import { styled } from "~/styles/stitches.config";

const HeaderContainer = styled("header", {
  backgroundColor: "$white",
  position: "sticky",
  zIndex: 1,
  top: 0,
  width: "100%",
  height: 78,
  px: "$3",
  py: "$2",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "$shadow1",
  "@bp1": {
    backgroundColor: "$white",
    height: 78,
    justifyContent: "center",
    py: "$5",
    boxShadow: "$shadow1",
  },
});

const Content = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 12,
  justifyContent: "stretch",
  "@bp1": {
    maxWidth: 1080,
    width: "100%",
    justifyContent: "space-between",
  },
});

export const Header = () => {
  return (
    <HeaderContainer>
      <Content>
        <svg
          fill="none"
          height="24"
          viewBox="0 0 72 24"
          width="72"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="a">
            <path d="m0 .189537h72v23.6209h-72z" />
          </clipPath>
          <g clipPath="url(#a)" fill="#4a25aa">
            <path d="m0 18.1753h4.06285c.23104 1.7836 1.55252 2.4785 3.23707 2.4785 1.28787 0 2.4113-.4951 2.4113-1.6845 0-1.2549-1.12343-1.7836-2.907-2.2463l-1.55253-.3961c-2.21266-.5281-4.72298-1.4199-4.72298-4.657 0-3.30065 2.90641-5.05362 6.17649-5.05362 4.4589 0 6.8096 2.4161 7.0335 5.94602h-4.06288c-.13863-2.0404-1.38509-2.80797-2.93762-2.80797-1.28786-.0012-2.17725.62657-2.17725 1.68337 0 1.1558 1.12283 1.4535 2.70836 1.8826l1.55253.3967c2.87336.7261 4.92106 2.0476 4.92106 5.1526 0 3.4021-2.907 4.9211-6.40817 4.9211-4.65937 0-7.103682-2.2133-7.33473-5.6154z" />
            <path d="m53.0517 7.47205h-4.2111v15.82115h4.2111z" />
            <path d="m72 20.3231v2.9971h-1.7764c-1.5459 0-2.7606-.3001-3.3223-1.8124-1.1186 1.4145-2.829 2.3027-5.4281 2.3027-3.3553 0-5.8212-1.579-5.8212-4.8358 0-3.1579 2.4005-4.7044 5.4281-5.0003l5.1317-.5263v-.6913c0-1.8094-1.1511-2.697-2.7606-2.697-1.7764 0-2.8494 1.01-3.1128 2.4905h-4.2147c.3288-3.4207 3.1128-5.8464 7.3215-5.8464 3.8492 0 6.9741 1.9408 6.9741 6.3493v6.0199c0 .8552.3948 1.25 1.1846 1.25zm-5.7894-3.9806-3.6848.3949c-1.5129.1644-2.6645.7892-2.6645 2.0722 0 1.3161 1.0202 1.7764 2.3356 1.7764 1.7764 0 4.0137-.8552 4.0137-3.4543z" />
            <path d="m24.5241 23.7751c4.7532 0 8.6064-3.8533 8.6064-8.6064 0-4.7532-3.8532-8.60644-8.6064-8.60644s-8.6064 3.85324-8.6064 8.60644c0 4.7531 3.8532 8.6064 8.6064 8.6064z" />
            <path d="m46.8241 1.01831c-.7945-.28866-2.2792-.828773-4.0154-.828773-3.1933 0-5.4413 1.694153-5.4413 5.082463v2.20906h-3.4207v3.29044h3.4207v12.5493h4.1702v-12.5493h4.2327v-3.29044h-4.2327v-1.98042c0-1.36828.815-1.98701 2.0201-1.98701.9962 0 2.0404.38468 2.5547.54011z" />
            <path d="m49.3681 5.37162h2.9244c.1082.00017.2145-.02731.3091-.07983.0945-.05251.174-.12833.231-.22023l2.859-4.638371h-3.7496c-.1243-.000051-.2458.036522-.3495.10515-.1036.068629-.1847.166271-.2332.280731z" />
          </g>
        </svg>
      </Content>
    </HeaderContainer>
  );
};
