import React from "react";
import { styled } from "~/styles/stitches.config";

export interface ButtonProps {
  title: string;
  type?: "primary" | "ghost";
  size?: "small" | "medium" | "large";
  block?: boolean;
  onClick?: VoidFunction;
}

const Container = styled("button", {
  outline: "none",
  border: "none",
  borderRadius: "$pill",
  cursor: "pointer",
  textAlign: "center",
  pointerEvents: "all",
  fontFamily: "'Moderat', sans-serif",
  variants: {
    block: {
      true: {
        display: "block",
      },
    },
    type: {
      primary: {
        backgroundColor: "$primary500",
        color: "$white",
        "&:hover": {
          backgroundColor: "$primary700",
        },
      },
      ghost: {
        backgroundColor: "$transparent",
        color: "$primary500",
      },
    },
    size: {
      small: {
        padding: "16px 24px",
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "16px",
        "@bp1": {
          fontSize: 16,
          lineHeight: "22px",
        },
      },
      medium: {},
      large: {},
    },
  },
});

export const Button = ({
  onClick,
  size = "small",
  block = false,
  type = "primary",
  title,
}: ButtonProps) => {
  return (
    <Container size={size} type={type} block={block} onClick={onClick}>
      {title}
    </Container>
  );
};
