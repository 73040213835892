import React from "react";
import { keyframes, styled } from "~/styles/stitches.config";
import Image from "next/image";

const MOBILE_BRAND_WIDTH = 120;

const translateInfinite = keyframes({
  "100%": { transform: `translateX(calc(-${MOBILE_BRAND_WIDTH}px * 6))` },
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  mb: "$10",
  position: "relative",
});

const Title = styled("p", {
  color: "$blueGray100",
  textAlign: "center",
  fontSize: 16,
  fontWeight: 300,
  lineHeight: "22px",
  mb: "$3",
  "@bp1": {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "22px",
  },
});

const BrandsContainer = styled("div", {
  width: "100%",
  height: 60,
  backgroundColor: "transparent",
  overflow: "hidden",
  position: "relative",
  "@bp1": {
    height: 80,
    maxWidth: 1080,
    width: "100%",
  },
});

const BrandHighWay = styled("div", {
  "@bp1": {
    display: "flex",
    flexDirection: "row",
  },
});

const BrandLane = styled("div", {
  display: "flex",
  height: "100%",
  width: `calc(${MOBILE_BRAND_WIDTH}px * 12)`,
  "@bp1": {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Brand = styled("div", {
  display: "block",
  width: MOBILE_BRAND_WIDTH,
  height: 60,
  backgroundColor: "transparent",
  animation: `${translateInfinite} 25s linear infinite`,
  position: "relative",
  "@bp1": {
    width: 160,
    height: 80,
    animation: "none",
  },
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  variants: {
    extra: {
      true: {
        "@bp1": {
          display: "none",
        },
      },
    },
  },
});

export const HomeBrands = () => {
  return (
    <Container>
      <Title>Nos acompañan en nuestra misión:</Title>
      <BrandsContainer>
        <BrandHighWay>
          <BrandLane>
            <Brand>
              <Image
                src={"/partners/SanAngelInn.png"}
                alt={"San Angel Inn"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand>
              <Image
                src={"/partners/MacHospitales.png"}
                alt={"Mac Hospitales"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand>
              <Image
                src={"/partners/HospitalAngeles.png"}
                alt={"Hospital Angeles"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand>
              <Image
                src={"/partners/Farmatodo.png"}
                alt={"FarmaTodo"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand>
              <Image
                src={"/partners/Christus.png"}
                alt={"Christus"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand>
              <Image
                src={"/partners/Chopo.png"}
                alt={"Chopo"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>

            <Brand extra aria-hidden>
              <Image
                src={"/partners/SanAngelInn.png"}
                alt={"San Angel Inn"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand extra aria-hidden>
              {" "}
              <Image
                src={"/partners/MacHospitales.png"}
                alt={"Mac Hospitales"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand extra aria-hidden>
              <Image
                src={"/partners/HospitalAngeles.png"}
                alt={"Hospital Angeles"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand extra aria-hidden>
              <Image
                src={"/partners/Farmatodo.png"}
                alt={"FarmaTodo"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand extra aria-hidden>
              <Image
                src={"/partners/Christus.png"}
                alt={"Christus"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
            <Brand extra aria-hidden>
              <Image
                src={"/partners/Chopo.png"}
                alt={"Chopo"}
                quality={100}
                fill
                loading={"lazy"}
              />
            </Brand>
          </BrandLane>
        </BrandHighWay>
      </BrandsContainer>
    </Container>
  );
};
