import React, { useMemo, useState } from "react";
import { DoctorFragment, SpecializationType } from "~/types/graphql";
import {
  DoctorProfileAddresses,
  DoctorProfileEducation,
  IconStatus,
  Toast,
} from "~/components";
import { styled } from "~/styles/stitches.config";
import Flex from "~/components/flex/Flex";
import { AttachmentLinkIcon } from "@sofia-eng/web-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

export interface DoctorProfileContent {
  doctor: DoctorFragment;
}

const PaddedSection = styled(Flex, {
  p: ["$2"],
});

const DoctorName = styled("h4", {
  fontSize: 18,
  fontWeight: 700,
  lineHeight: "24px",
});

const HeaderSpecialization = styled("div", {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  color: "$blueGray200",
});

const Link = styled(Flex, {
  color: "$primary500",
  fontSize: 12,
  fontWeight: 700,
  lineHeight: "16px",
  cursor: "pointer",
});

export const DoctorProfileContent = ({ doctor }: DoctorProfileContent) => {
  const [toastOpened, setToastOpened] = useState<boolean>(false);
  const primarySpecialty = useMemo(() => {
    return doctor.doctorSpecialistDetails?.filter(
      specialty =>
        specialty.medicalSpecialization.type ===
        SpecializationType.Specialization,
    )[0];
  }, [doctor]);

  const doctorSpecializations = useMemo(() => {
    if (
      doctor.doctorSpecialistDetails &&
      doctor.doctorSpecialistDetails.length > 0
    ) {
      return doctor.doctorSpecialistDetails[0];
    }
  }, [doctor]);

  const onLinkCopied = (text: string, result: boolean) => {
    if (result) {
      setToastOpened(true);
    }
  };

  return (
    <div>
      <PaddedSection gap={"1"}>
        <Flex direction={"row"} gap={"1"} align={"center"}>
          <IconStatus icon={"DoctorIcon"} />
          <DoctorName>{doctor.prefixedFullName}</DoctorName>
        </Flex>
        <div>
          {primarySpecialty && (
            <HeaderSpecialization>
              {`${primarySpecialty.specializationName} | Ced. Prof. ${primarySpecialty.specialistNumber}`}
            </HeaderSpecialization>
          )}
          {(!primarySpecialty ||
            primarySpecialty.specializationName !==
              doctorSpecializations?.specializationName) && (
            <HeaderSpecialization>
              {`${doctorSpecializations?.specializationName} | Ced. Prof. ${doctorSpecializations?.specialistNumber}`}
            </HeaderSpecialization>
          )}
        </div>
        <CopyToClipboard text={window.location.href} onCopy={onLinkCopied}>
          <Link direction={"row"} gap={"1"} align={"center"}>
            <AttachmentLinkIcon size={"sm"} color={"primary500"} />
            Copiar link del doctor
          </Link>
        </CopyToClipboard>
      </PaddedSection>
      <hr />
      <PaddedSection>
        <DoctorProfileAddresses doctor={doctor} />
      </PaddedSection>
      {doctor.doctorSpecialistDetails ? (
        <>
          <hr />
          <DoctorProfileEducation
            doctorSpecializations={doctor.doctorSpecialistDetails ?? []}
          />
        </>
      ) : null}
      <Toast
        text={"¡Link copiado correctamente!"}
        open={toastOpened}
        onOpenChange={setToastOpened}
      />
    </div>
  );
};
