import React, { HTMLAttributeAnchorTarget, ReactNode } from "react";
import { styled } from "~/styles/stitches.config";
import { ArrowRightIcon } from "@sofia-eng/web-icons";

export interface HomeFeatureCardProps {
  tagColor: string;
  tagTitle: string;
  tagIcon: ReactNode;
  text: ReactNode;
  href: string;
  target?: HTMLAttributeAnchorTarget;
}

const Container = styled("div", {
  p: ["$3"],
  display: "flex",
  flexDirection: "column",
  gap: 24,
  alignItems: "flex-start",
  borderRadius: "8px",
  backgroundColor: "$blueGray50",
  gridColumn: "1/-1",
  "@bp1": {
    gridColumn: "span 6",
  },
});

const Tag = styled("div", {
  px: "$2",
  paddingTop: 4,
  paddingBottom: 4,
  gap: 8,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  borderRadius: "$pill",
  color: "$blueGray300",
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "20px",
});

const TextContent = styled("p", {
  color: "$blueGray300",
  fontSize: 16,
  fontWeight: 300,
  lineHeight: "22px",
  "@bp1": {
    fontSize: 22,
    lineHeight: "32px",
  },
});

const LinkContainer = styled("div", {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-end",
});

const Link = styled("a", {
  color: "$primary500",
  textDecoration: "none",
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: 700,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
  "@bp1": {
    fontSize: 16,
    lineHeight: "20px",
  },
});

export const HomeFeatureCard = ({
  text,
  href,
  tagColor,
  tagIcon,
  tagTitle,
  target = "_blank",
}: HomeFeatureCardProps) => {
  return (
    <Container>
      <Tag css={{ backgroundColor: tagColor }}>
        {tagIcon}
        {tagTitle}
      </Tag>
      <TextContent>{text}</TextContent>
      <LinkContainer>
        <Link href={href} target={target} referrerPolicy={"no-referrer"}>
          Ver todos
          <ArrowRightIcon color={"primary500"} />
        </Link>
      </LinkContainer>
    </Container>
  );
};
