import React, { useMemo } from "react";
import { Color } from "@sofia-eng/design-system-core";
import * as Icons from "@sofia-eng/web-icons";
import { styled } from "~/styles/stitches.config";

export declare type Icon = keyof typeof Icons;
export declare type IconStatusState =
  | "info"
  | "warning"
  | "success"
  | "error"
  | "default"
  | "disabled";

export interface IconStatusProps {
  icon: Icon;
  state?: IconStatusState;
}

const Container = styled("div", {
  display: "flex",
  padding: 8,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 8,
  width: 40,
  height: 40,
  variants: {
    state: {
      info: {
        backgroundColor: "$info100",
      },
      warning: {
        backgroundColor: "$warning100",
      },
      success: {
        backgroundColor: "$success100",
      },
      error: {
        backgroundColor: "$error100",
      },
      default: {
        backgroundColor: "$primary50",
      },
      disabled: {
        backgroundColor: "$blueGray50",
      },
    },
  },
});

export const IconStatus = ({ icon, state = "default" }: IconStatusProps) => {
  const Component = Icons[icon];

  const iconColor: Color = useMemo(() => {
    switch (state) {
      case "default":
      default:
        return "primary500";
      case "info":
        return "info500";
      case "warning":
        return "warning500";
      case "success":
        return "success500";
      case "error":
        return "error500";
      case "disabled":
        return "blueGray300";
    }
  }, [state]);

  return (
    <Container state={state}>
      <Component color={iconColor} size={"md"} />
    </Container>
  );
};
