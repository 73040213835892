import React from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";

import { styled } from "~/styles/stitches.config";
import {
  AccordionMultipleProps,
  AccordionSingleProps,
} from "@radix-ui/react-accordion";

const AccordionRoot = styled(RadixAccordion.Root, {
  width: "100%",
});

export const Accordion = ({
  children,
  ...props
}: AccordionSingleProps | AccordionMultipleProps) => {
  return <AccordionRoot {...props}>{children}</AccordionRoot>;
};
