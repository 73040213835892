import React, { ReactNode } from "react";
import * as DialogS from "@radix-ui/react-dialog";
import { keyframes, styled } from "~/styles/stitches.config";
import { CrossIcon } from "@sofia-eng/web-icons";

export interface DialogProps {
  open?: boolean;
  children?: ReactNode;
  onDismiss?: VoidFunction;
}

export const Dialog = ({ open, children, onDismiss }: DialogProps) => {
  return (
    <DialogS.Root
      open={open}
      onOpenChange={open => {
        if (!open) {
          onDismiss?.();
        }
      }}
    >
      <DialogS.Portal>
        <DialogOverlay />
        <DialogContent>
          {children}
          <DialogS.Close asChild>
            <IconButton aria-label="Close">
              <CrossIcon size={"md"} color={"blueGray300"} />
            </IconButton>
          </DialogS.Close>
        </DialogContent>
      </DialogS.Portal>
    </DialogS.Root>
  );
};

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const DialogOverlay = styled(DialogS.Overlay, {
  backgroundColor: "rgba(0, 0, 0, 0.50)",
  position: "fixed",
  inset: 0,
  zIndex: 5,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const DialogContent = styled(DialogS.Content, {
  zIndex: 6,
  backgroundColor: "$white",
  borderRadius: 8,
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "522px",
  maxWidth: "522px",
  maxHeight: "85vh",
  padding: 0,
  overflow: "hidden",
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  "&:focus": { outline: "none" },
});

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 25,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 16,
  right: 16,
  cursor: "pointer",
});
