import { ArrowLeftIcon, ArrowRightIcon } from "@sofia-eng/web-icons";
import React from "react";
import { styled } from "~/styles/stitches.config";

export interface ListPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const PAGINATION_GREY = "#bababa";

const Container = styled("div", {
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});

const Button = styled("button", {
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  color: "$primary500",
  textDecoration: "none",
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: 700,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
  "@bp1": {
    fontSize: 16,
    lineHeight: "20px",
  },
});

const LinkContainer = styled("div", {
  flexDirection: "column",
  justifyContent: "flex-end",
});

const Info = styled("span", {
  fontWeight: 700,
  fontSize: 13,
  color: PAGINATION_GREY,
});

const ListPagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: ListPaginationProps) => {
  const handlePrevious = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage + 1 < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <Container>
      <LinkContainer>
        <Button
          onClick={handlePrevious}
          disabled={currentPage === 0}
          style={currentPage === 0 ? { color: PAGINATION_GREY } : {}}
        >
          <ArrowLeftIcon
            style={
              currentPage === 0
                ? { color: PAGINATION_GREY }
                : { color: "$primary500" }
            }
          />
          Anterior
        </Button>
      </LinkContainer>
      <Info>
        Página {currentPage + 1} de {totalPages}
      </Info>
      <Button
        onClick={handleNext}
        disabled={currentPage + 1 === totalPages}
        style={currentPage + 1 === totalPages ? { color: PAGINATION_GREY } : {}}
      >
        Siguiente
        <ArrowRightIcon
          style={
            currentPage + 1 === totalPages
              ? { color: PAGINATION_GREY }
              : { color: "$primary500" }
          }
        />
      </Button>
    </Container>
  );
};

export default ListPagination;
