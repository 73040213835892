import React from "react";
import { styled } from "~/styles/stitches.config";
import { DoctorFragment } from "~/types/graphql";
import Flex from "~/components/flex/Flex";
import { EyeShowVisibleIcon } from "@sofia-eng/web-icons";
import { useRouter } from "next/router";
import { DOCTOR_ID_QUERY_VAR } from "~/pages/lista";

export interface DoctorMapPinProps {
  lat: number;
  lng: number;
  doctor: DoctorFragment;
  showBalloon?: boolean;
  onClick?: VoidFunction;
  hovered?: boolean;
}

const Pin = styled("svg", {
  cursor: "pointer",
});

const Container = styled("div", {
  width: 48,
  height: 40,
  borderRadius: "100%",
  display: "block",
  position: "relative",
  [`& ${Pin}`]: {
    "& .outline": {
      fill: "$white",
      transition: "fill 0.25s ease-out",
    },
    "& .bg": {
      fill: "$primary200",
      transition: "fill 0.25s ease-out",
    },
  },
  "&:hover": {
    zIndex: 5,
    [`& ${Pin}`]: {
      "& .outline": {
        fill: "$white",
      },
      "& .bg": {
        fill: "$primary500",
      },
    },
  },
  variants: {
    hovered: {
      true: {
        zIndex: 5,
        [`& ${Pin}`]: {
          "& .outline": {
            fill: "$white",
          },
          "& .bg": {
            fill: "$primary500",
          },
        },
      },
    },
    selected: {
      true: {
        zIndex: 5,
        [`& ${Pin}`]: {
          "& .outline": {
            fill: "$primary500 !important",
          },
          "& .bg": {
            fill: "$white !important",
          },
        },
      },
    },
  },
});

const InfoContainer = styled("div", {
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: 302,
  gap: 16,
  p: ["$2"],
  bottom: -4,
  left: "50%",
  transform: "translate(-50%, 100%)",
  backgroundColor: "$white",
  borderRadius: 8,
});

const Link = styled("div", {
  cursor: "pointer",
  pointerEvents: "all",
  color: "$primary500",
  fontSize: 12,
  fontWeight: 700,
  lineHeight: "16px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
  minWidth: 40,
});

const DoctorTitle = styled("div", {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "24px",
  color: "$blueGray300",
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const DoctorSpecialties = styled("div", {
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "18px",
  color: "$blueGray200",
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const DoctorMapPin = ({
  doctor,
  showBalloon,
  onClick,
  hovered,
}: DoctorMapPinProps) => {
  const router = useRouter();

  const onViewDoctor = () => {
    router.push(
      {
        query: {
          ...router.query,
          [DOCTOR_ID_QUERY_VAR]: doctor.uuid,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <Container selected={showBalloon} hovered={hovered}>
      <Pin
        fill="none"
        height="48"
        viewBox="0 0 56 48"
        width="56"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <filter
          id="a"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="47.3989"
          width="56"
          x="0"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_2169_23477"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2169_23477"
            mode="normal"
            result="shape"
          />
        </filter>
        <mask id="b" fill="#fff">
          <path
            clipRule="evenodd"
            d="m20 2c-8.8366 0-16 7.16344-16 16 0 8.8366 7.1634 16 16 16h3.3977l2.7775 6.2149c.7055 1.5786 2.9464 1.5786 3.6519 0l2.7775-6.2149h3.3954c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16z"
            fill="#fff"
            fillRule="evenodd"
          />
        </mask>
        <g filter="url(#a)">
          <path
            className="bg"
            clipRule="evenodd"
            d="m20 2c-8.8366 0-16 7.16344-16 16 0 8.8366 7.1634 16 16 16h3.3977l2.7775 6.2149c.7055 1.5786 2.9464 1.5786 3.6519 0l2.7775-6.2149h3.3954c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16z"
            fill="#fff"
            fillRule="evenodd"
          />
          <g className={"outline"} fill="#4a25aa">
            <path
              d="m23.3977 34 .9129-.408-.2645-.592h-.6484zm2.7775 6.2149-.913.408zm3.6519 0 .913.408zm2.7775-6.2149v-1h-.6484l-.2645.592zm-27.6046-16c0-8.28427 6.7157-15 15-15v-2c-9.3888 0-17 7.61116-17 17zm15 15c-8.2843 0-15-6.7157-15-15h-2c0 9.3888 7.6112 17 17 17zm3.3977 0h-3.3977v2h3.3977zm3.6905 6.8069-2.7776-6.2149-1.8259.816 2.7775 6.2149zm1.8259 0c-.3527.7893-1.4732.7893-1.8259 0l-1.826.816c1.0583 2.3679 4.4196 2.3679 5.4779 0zm2.7776-6.2149-2.7776 6.2149 1.826.816 2.7775-6.2149zm4.3083-.592h-3.3954v2h3.3954zm15-15c0 8.2843-6.7157 15-15 15v2c9.3888 0 17-7.6112 17-17zm-15-15c8.2843 0 15 6.71573 15 15h2c0-9.38884-7.6112-17-17-17zm-16 0h16v-2h-16z"
              mask="url(#b)"
            />
            <path d="m30 8.09955h-4c-1.5188 0-2.75 1.23122-2.75 2.74995v4c0 2.6234 2.1267 4.75 4.75 4.75l.218-.0051c2.5412-.1247 4.532-2.2097 4.532-4.7449v-4c0-1.51873-1.2312-2.74995-2.75-2.74995zm-4 1.5h4c.6904 0 1.25.55965 1.25 1.24995v4c0 1.7345-1.3623 3.1612-3.0872 3.246l-.1812.0042c-1.7765-.0002-3.2316-1.4552-3.2316-3.2502v-4c0-.6903.5596-1.24995 1.25-1.24995z" />
            <path d="m29.9189 17.5269c.3684-.0921.7412.1055.8782.4486l.0313.0971.743 2.972c.1005.4018-.1439.809-.5457.9095-.3684.0921-.7412-.1055-.8782-.4486l-.0313-.0971-.743-2.972c-.1005-.4018.1439-.809.5457-.9095z" />
            <path d="m25.1738 18.0726c.1004-.4018.5076-.6462.9095-.5457.3684.0921.6044.4419.5637.8091l-.018.1004-.743 2.972c-.1005.4018-.5077.6462-.9095.5457-.3684-.0921-.6044-.4419-.5638-.8091l.0181-.1004z" />
            <path d="m24 20.2561c.3797 0 .6935.2821.7431.6482l.0069.1018v3.8434c0 .4142-.3358.75-.75.75-.3797 0-.6935-.2822-.7432-.6482l-.0068-.1018v-3.8434c0-.4142.3358-.75.75-.75z" />
            <path d="m24 24.0995c-1.2426 0-2.25 1.0074-2.25 2.25s1.0074 2.25 2.25 2.25 2.25-1.0074 2.25-2.25-1.0074-2.25-2.25-2.25zm0 1.5c.4142 0 .75.3358.75.75s-.3358.75-.75.75-.75-.3358-.75-.75.3358-.75.75-.75z" />
            <path d="m32 20.2561c.3797 0 .6935.2821.7432.6482l.0068.1018v3.8434c0 .4142-.3358.75-.75.75-.3797 0-.6935-.2822-.7432-.6482l-.0068-.1018v-3.8434c0-.4142.3358-.75.75-.75z" />
            <path d="m32 24.0995c1.5188 0 2.75 1.2312 2.75 2.7555-.0035.4844-.1353.9593-.382 1.3763-.2109.3565-.6708.4746-1.0274.2637-.3268-.1933-.4532-.5959-.3096-.9363l.0459-.091c.1118-.189.1715-.4041.1731-.6182 0-.6904-.5596-1.25-1.25-1.25s-1.25.5596-1.25 1.2445c.0013.1757.0397.3485.1123.5073l.0608.1164c.2108.3565.0928.8164-.2637 1.0273-.3566.2109-.8165.0928-1.0274-.2637-.2467-.417-.3785-.8919-.382-1.3818 0-1.5188 1.2312-2.75 2.75-2.75z" />
            <path d="m32 12.0995c.4142 0 .75.3358.75.75 0 .3797-.2822.6935-.6482.7432l-.1018.0068h-8c-.4142 0-.75-.3357-.75-.75 0-.3797.2822-.6934.6482-.7431l.1018-.0069z" />
            <path d="m30.6475 20.4641c.3194-.2126.7013-.3066 1.0797-.2692l.1618.0242 2.8462.569c1.688.3374 2.9207 1.7772 3.0096 3.4797l.0052.1975v1.3842c0 .4142-.3358.75-.75.75-.3797 0-.6935-.2822-.7432-.6482l-.0068-.1018v-1.3842c0-1.0161-.6795-1.8985-1.6453-2.1673l-.1636-.0391-2.8463-.569c-.0403-.008-.0821.0002-.1156.0225-2.1067 1.4052-4.8517 1.4052-6.9577.0005-.0228-.0152-.049-.0239-.0759-.0257l-.0406.0027-2.8461.569c-.9964.1993-1.7284 1.0386-1.8027 2.0384l-.0062.168v1.3842c0 .4142-.3358.75-.75.75-.3797 0-.6935-.2822-.7431-.6482l-.0069-.1018v-1.3842c0-1.7214 1.1701-3.2124 2.8221-3.6335l.1927-.0437 2.846-.569c.43-.0861.8766.0021 1.2424.2455 1.6026 1.0689 3.691 1.0689 5.2943-.0005z" />
          </g>
        </g>
      </Pin>
      {showBalloon ? (
        <InfoContainer>
          <Flex direction={"column"} css={{ flex: 1, overflow: "hidden" }}>
            <DoctorTitle>{doctor.prefixedFullName}</DoctorTitle>
            <DoctorSpecialties>Especialidades</DoctorSpecialties>
          </Flex>
          <Link onClick={onViewDoctor}>
            <EyeShowVisibleIcon size={"sm"} color={"primary500"} />
            <span>Ver</span>
          </Link>
        </InfoContainer>
      ) : null}
    </Container>
  );
};
