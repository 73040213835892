import React from "react";
import * as ToastR from "@radix-ui/react-toast";
import { keyframes, styled } from "~/styles/stitches.config";
import { isSsr } from "~/utils/server";
import { CheckIcon } from "@sofia-eng/web-icons";

export interface ToastProps {
  text: string;
  open?: boolean;
  onOpenChange?: (value: boolean) => void;
}

export const Toast = ({ text, onOpenChange, open }: ToastProps) => {
  if (isSsr) {
    return null;
  }
  return (
    <ToastRoot open={open} onOpenChange={onOpenChange}>
      <CheckIcon color={"white"} size={"md"} />
      <ToastTitle>{text}</ToastTitle>
    </ToastRoot>
  );
};

const VIEWPORT_PADDING = 25;

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: "translateX(0)" },
});

const swipeOut = keyframes({
  from: { transform: "translateX(var(--radix-toast-swipe-end-x))" },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

const ToastRoot = styled(ToastR.Root, {
  backgroundColor: "$blueGray300",
  borderRadius: 8,
  padding: 16,
  display: "flex",
  gap: 8,
  justifyContent: "flex-start",
  alignItems: "center",

  '&[data-state="open"]': {
    animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${hide} 100ms ease-in`,
  },
  '&[data-swipe="move"]': {
    transform: "translateX(var(--radix-toast-swipe-move-x))",
  },
  '&[data-swipe="cancel"]': {
    transform: "translateX(0)",
    transition: "transform 200ms ease-out",
  },
  '&[data-swipe="end"]': {
    animation: `${swipeOut} 100ms ease-out`,
  },
});

const ToastTitle = styled(ToastR.Title, {
  fontWeight: 700,
  color: "$white",
  fontSize: 12,
  lineHeight: "16px",
});
